$screen-mobiles-xs: 350px;
$screen-mobiles-s: 420px;
$screen-mobiles: 580px;
$screen-mobiles-l: 750px;
$screen-tablets: 900px;
$screen-tablets-l: 1100px;
$screen-tablets-xl: 1500px;

@import 'react-datepicker/dist/react-datepicker.css';

@font-face {
  font-family: 'Anakotmai-Light';
  src: url(font-path('Anakotmai-Light.otf'));
  font-weight: 400;
  // font-display: swap;
}

// `font-weight` is used once for an animation.
@font-face {
  font-family: 'Anakotmai-Light';
  src: url(font-path('Anakotmai-Bold.otf'));
  font-weight: 700;
  // font-display: swap;
}

@font-face {
  font-family: 'Anakotmai-Medium';
  src: url(font-path('Anakotmai-Medium.otf'));
  font-weight: 400;
  // font-display: swap;
}

@font-face {
  font-family: 'Anakotmai-Bold';
  src: url(font-path('Anakotmai-Bold.otf'));
  font-weight: 400;
  // font-display: swap;
}

// Use for payment UI
@font-face {
  font-family: 'Anakotmai';
  src: url(font-path('Anakotmai-Light.otf'));
  font-weight: 400;
}

@font-face {
  font-family: 'Anakotmai';
  src: url(font-path('Anakotmai-Medium.otf'));
  font-weight: 700;
}

@mixin overlay {
  position: fixed;
  background-color: rgba(#161616, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s;
}

:root {
  --global-space: 20px;
  --app-width: 1110px;
  --app-padding: calc(var(--global-space) * 1);
  --NavLanding-h: 72px;
  --NavLanding__links-h: 62px;

  --c-primary: #00aefd;
  --c-primary-1: #55c4ff;
  --c-primary-2: #16a6f3;
  --c-primary-3: #0895e0;
  --c-primary-4: #007ec1;
  --c-primary-5: #006195;
  --c-primary-6: #004266;

  @media (max-width: $screen-mobiles-xs) {
    --global-space: 15px;
  }
}

html {
  box-sizing: border-box;
}

body,
input,
select,
textarea,
button {
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;

  color: #2b2b2b;
}

body {
  overflow-wrap: break-word;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  overflow-anchor: none;
  line-height: calc(
    3px + 2.5ex + 3px
  ); // Trying out https://hugogiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height/.
}

a,
button {
  text-decoration: none;
  color: currentColor;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;

  &[href]:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  font-size: inherit;
}

strong {
  font-family: Anakotmai-Bold;
}

[disabled] {
  opacity: 0.4;
  cursor: default;
  text-decoration: none !important;
  pointer-events: none; // To prevent `onClick` triggering.

  &[title] {
    cursor: help !important;
  }
}

figure,
iframe,
img {
  display: block;
  max-width: 100%;
  border: none;
}

ul,
ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
}

svg {
  display: block;
  max-width: 100%;
}

video {
  display: block;
  width: 100%;
}

audio {
  display: block;
}

[data-react-class] {
  display: contents;
}

.container {
  padding: 0 var(--app-padding);
  max-width: calc(var(--app-padding) * 2 + var(--app-width));
  margin: 0 auto;
}

.NavLanding {
  box-shadow: inset 0 -1px 0 0 #e8e8e8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: #ffffff;

  > .container {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: calc(var(--global-space) * 1);
    justify-content: space-between;
    height: var(--NavLanding-h);
    align-items: center;
    position: relative;

    @media (max-width: $screen-tablets) {
      grid-template-columns: 1fr;
      justify-items: center;
    }

    @media (max-width: $screen-mobiles-l) {
      grid-template-columns: max-content max-content;
      justify-content: space-between;
      background-color: #fff;
      box-shadow: inset 0 -1px 0 0 #e8e8e8;
    }
  }

  &__h1 {
    transform: translateY(-2px);

    &__a {
      font-size: 0;
      text-indent: -99999px;
      display: block;
      width: 123px;
      height: 49px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(image-path('landing/NavLanding__h1__a.svg'));

      @media (max-width: $screen-mobiles) {
        width: 92px;
        height: 37px;
      }
    }
  }

  &__overlay {
    @include overlay;
    display: none;
    opacity: 0;
    pointer-events: none;

    &--active {
      opacity: 1;
      pointer-events: auto;
    }

    @media (max-width: $screen-mobiles-l) {
      display: block;
    }
  }

  &__handle {
    display: none;
    padding: calc(var(--global-space) * 0.5);
    box-sizing: content-box;
    transform: translateX(calc(var(--global-space) * 0.5));

    @media (max-width: $screen-mobiles-l) {
      display: block;
    }

    svg {
      width: inherit;
    }
  }

  &__links {
    display: grid;
    grid-gap: calc(var(--global-space) * 1);
    height: 100%;
    transform: translateX(calc(var(--app-padding) * 1));
    grid-template-columns: repeat(
      calc(var(--NavLanding__links-count) + 1),
      max-content
    );

    @media (max-width: $screen-tablets) {
      position: fixed;
      bottom: 0;
      left: 0;
      grid-template-columns: repeat(
        calc(var(--NavLanding__links-count) + 1),
        1fr
      );
      background-color: #fff;
      border-top: 1px solid #e8e8e8;
      transform: none;
      grid-gap: 0;
      width: 100%;
      height: var(--NavLanding__links-h);
    }

    @media (max-width: $screen-mobiles-l) {
      position: absolute;
      bottom: unset;
      top: 100%;
      width: 100%;
      border-top: none;
      grid-template-columns: 1fr;
      background-color: #fff;
      height: auto;
      opacity: 0;
      transform: translateY(-4px);
      pointer-events: none;
      transition: opacity 0.2s, transform 0.2s;
      will-change: opacity, transform;
      padding-top: calc(var(--global-space) * 0.25);
    }

    &--active {
      @media (max-width: $screen-mobiles-l) {
        opacity: 1;
        transform: none;
        pointer-events: auto;
      }
    }

    &__a {
      font-family: Anakotmai-Medium;
      font-size: 16px;
      color: #2b2b2b;
      white-space: nowrap;
      padding: 0 calc(var(--global-space) * 1);
      display: grid;
      align-items: center;

      @media (max-width: $screen-tablets) {
        text-align: center;
      }

      @media (max-width: $screen-mobiles-l) {
        padding-top: calc(var(--global-space) * 1);
        padding-bottom: calc(var(--global-space) * 1);
      }

      &:not(:last-child) {
        @media (max-width: $screen-tablets) {
          border-right: 1px solid #e8e8e8;
        }
      }

      &--orange {
        background-color: #ff8f3a;
        font-family: Anakotmai-Bold;
        color: #ffffff;
      }
    }
  }
}

.MainLanding {
  padding-top: var(--NavLanding-h);
  display: grid;
  grid-template-rows: 1fr max-content;
  min-height: 100vh;

  @media (max-width: $screen-tablets) {
    padding-bottom: var(--NavLanding__links-h);
  }

  @media (max-width: $screen-mobiles-l) {
    padding-bottom: 0;
  }
}

.Home {
  &__hero {
    padding: calc(var(--global-space) * 8) 0 calc(var(--global-space) * 13);
    overflow: hidden;

    @media (max-width: $screen-tablets) {
      padding: calc(var(--global-space) * 2) 0;
      text-align: center;
    }

    > .container {
      display: grid;
      justify-content: start;

      @media (max-width: $screen-tablets) {
        display: block;
      }
    }

    &__wrapper {
      position: relative;
    }

    &__h2 {
      font-family: Anakotmai-Medium;
      font-size: 42px;
      color: #2b2b2b;

      @media (max-width: $screen-mobiles) {
        font-size: 29px;
      }

      span {
        color: #00aefd;
      }

      br {
        @media (max-width: $screen-mobiles-l) {
          display: none;
        }
      }
    }

    &__p {
      margin-top: calc(var(--global-space) * 1);
      font-family: Anakotmai-Light;
      font-size: 24px;
      color: #2b2b2b;

      @media (max-width: $screen-mobiles) {
        font-size: 16px;
      }

      br {
        @media (max-width: $screen-mobiles-l) {
          display: none;
        }
      }
    }

    .Buttons {
      margin-top: calc(var(--global-space) * 1.5);

      @media (max-width: $screen-tablets) {
        justify-content: center;
      }
    }

    &__small {
      margin-top: calc(var(--global-space) * 2);
      display: block;
      font-family: Anakotmai-Medium;
      font-size: 16px;
      color: #888888;
    }

    &__image {
      position: absolute;
      top: calc(50%);
      left: calc(100%);
      transform: translateY(calc(-50%)) translateX(10%);
      z-index: -1;
      width: 535px;
      height: 679px;

      @media (max-width: $screen-tablets) {
        position: relative;
        transform: none;
        width: unset;
        height: unset;
        top: unset;
        left: unset;
        z-index: 1;
        margin-top: calc(var(--global-space) * 2);
      }

      &::before {
        content: '';
        display: block;
        width: 1156px;
        height: 1061px;
        background-image: url(image-path('landing/Home__hero__image.svg'));
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-55%);

        @media (max-width: $screen-tablets) {
          display: none;
        }
      }
    }

    &__img {
      width: 100%;
      height: 100%;
      position: relative;

      @media (max-width: $screen-tablets) {
        width: unset;
        height: unset;
        margin: 0 auto;
        transform: translateX(11%);
      }
    }
  }

  &__can {
    padding-bottom: calc(var(--global-space) * 3);

    @media (max-width: $screen-tablets) {
      display: none;
    }

    &__h3 {
      font-family: Anakotmai-Medium;
      font-size: 24px;
      color: #888888;
      text-align: center;
    }

    &__dl {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 0 calc(var(--global-space) * 1);
      margin-top: calc(var(--global-space) * 2);

      &__item {
        display: grid;
        grid-template-rows: 1fr max-content;
        grid-gap: calc(var(--global-space) * 1);
        color: #00aefd;

        --Home__can__dl__item__hr-bcg: #e8e8e8;
        --Home__can__dl__item__img-tf: none;
        --Home__can__dl__item__dt-fw: 400;
        --Home__can__dl__item__dt-c: #2b2b2b;

        &:hover {
          --Home__can__dl__item__hr-bcg: currentColor;
          --Home__can__dl__item__img-tf: scale(1.2);
          --Home__can__dl__item__dt-fw: 700;
          --Home__can__dl__item__dt-c: currentColor;
        }

        &:nth-of-type(2) {
          color: #ff8f3a;
        }

        &:nth-of-type(3) {
          color: #926eed;
        }

        &:nth-of-type(4) {
          color: #74cd80;
        }

        &:nth-of-type(5) {
          color: #fca091;
        }

        &:nth-of-type(6) {
          color: #ffd971;
        }

        &__img {
          grid-row: 1 / 2;
          justify-self: center;
          align-self: end;
          transform: var(--Home__can__dl__item__img-tf);
          transition: transform 0.2s;
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
          will-change: transform;
        }

        &__dt {
          font-family: Anakotmai-Light;
          font-weight: var(--Home__can__dl__item__dt-fw);
          font-size: 20px;
          color: var(--Home__can__dl__item__dt-c);
          text-align: center;
          transition: font-weight 0.2s;
          transition-timing-function: ease-in-out;
          will-change: font-weight;
        }

        &__hr {
          margin-top: calc(var(--global-space) * 1);
          border: none;
          display: block;
          height: 8px;
          transition: background-color 0.3s;
          will-change: background-color;
          background-color: var(--Home__can__dl__item__hr-bcg);
          border-radius: 4px;
          grid-row: 3 / 4;
        }
      }
    }
  }

  &__why {
    background-color: #00aefd;
    overflow: hidden;

    @media (max-width: $screen-mobiles) {
      margin-top: calc(var(--global-space) * -10);
    }

    > .container {
      display: grid;
      justify-content: start;

      @media (max-width: $screen-tablets) {
        justify-content: center;
      }
    }

    &__wrapper {
      position: relative;
      padding: calc(var(--global-space) * 4) 0 calc(var(--global-space) * 6);

      @media (max-width: $screen-tablets) {
        padding-bottom: 0;
      }

      @media (max-width: $screen-mobiles) {
        padding: calc(var(--global-space) * 11) 0 calc(var(--global-space) * 3);
      }

      &::after {
        content: '';
        display: block;
        width: 727px;
        height: 445px;
        background-image: url(image-path('landing/Home__why.png'));
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        bottom: 0;
        left: calc(100%);
        // mix-blend-mode: darken;

        @media (max-width: $screen-tablets) {
          position: static;
          width: 477px;
          height: 292px;
          margin: calc(var(--global-space) * 1) auto 0;
        }

        @media (max-width: $screen-mobiles) {
          display: none;
        }
      }
    }

    &__h2 {
      font-family: Anakotmai-Medium;
      font-size: 52px;
      color: #ffffff;

      @media (max-width: $screen-tablets) {
        text-align: center;
      }

      @media (max-width: $screen-mobiles) {
        font-size: 40px;
      }
    }

    &__content {
      font-family: Anakotmai-Medium;
      font-size: 24px;
      color: #ffffff;
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      margin-top: calc(var(--global-space) * 1);
      position: relative;
      z-index: 1;

      @media (max-width: $screen-mobiles) {
        font-size: 20px;
      }

      br {
        @media (max-width: $screen-mobiles) {
          display: none;
        }
      }
    }

    &__ul {
      counter-reset: counter-stats-item;
      content: counter(counter-stats-item);
      display: grid;
      grid-gap: calc(var(--global-space) * 0.25);

      &__li {
        counter-increment: counter-stats-item;

        &::before {
          content: counter(counter-stats-item) '. ';
          display: inline;
        }
      }
    }
  }

  &__start {
    padding: calc(var(--global-space) * 7) 0;
    font-family: Anakotmai-Light;
    font-size: 24px;
    color: #2b2b2b;

    @media (max-width: $screen-mobiles) {
      font-size: 20px;
      padding: calc(var(--global-space) * 4) 0;
    }

    &__h2 {
      font-family: Anakotmai-Medium;
      font-size: 52px;
      color: #2b2b2b;
      text-align: center;

      @media (max-width: $screen-mobiles) {
        font-size: 32px;
      }
    }

    &__p {
      text-align: center;
      margin-top: calc(var(--global-space) * 1);
    }

    &__image {
      display: grid;
      justify-content: center;
      margin-top: calc(var(--global-space) * 2);

      @media (max-width: $screen-mobiles) {
        display: none;
      }
    }

    &__dl {
      margin-top: calc(var(--global-space) * 0.5);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: calc(var(--global-space) * 2);
      justify-content: center;

      @media (max-width: $screen-mobiles) {
        grid-template-columns: 1fr;
        margin-top: calc(var(--global-space) * 1);
      }

      &__item {
        &__dt {
          grid-row: 2 / 3;
          text-align: center;

          &__img {
            display: none;

            @media (max-width: $screen-mobiles) {
              display: block;
              margin: 0 auto calc(var(--global-space) * 0.75);
            }
          }
        }
      }
    }

    &__blockquote {
      font-family: Anakotmai-Light;
      font-size: 34px;
      color: #2b2b2b;
      position: relative;
      text-align: center;
      justify-self: center;

      span {
        font-family: Anakotmai-Medium;
      }

      &::before,
      &::after {
        content: '';
        display: block;
        width: 16px;
        height: 14px;
        background-image: url(image-path(
          'landing/Home__start__blockquote.svg'
        ));
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        top: calc(var(--global-space) * -0.5);
        left: calc(var(--global-space) * -1.5);
        position: absolute;

        @media (max-width: $screen-mobiles-l) {
          display: none;
        }
      }

      &::after {
        left: unset;
        right: calc(var(--global-space) * -1.5);
        transform: rotateZ(180deg);
      }

      &__wrapper {
        margin-top: calc(var(--global-space) * 6);
        display: grid;
        justify-items: center;

        @media (max-width: $screen-mobiles) {
          margin-top: calc(var(--global-space) * 3);
        }
      }
    }
  }

  &__pricing {
    background-color: #fafdff;
    padding: calc(var(--global-space) * 6) 0;

    @media (max-width: $screen-mobiles) {
      padding: calc(var(--global-space) * 3) 0;
    }

    &__h2 {
      font-family: Anakotmai-Medium;
      font-size: 44px;
      color: #2b2b2b;
      text-align: center;

      @media (max-width: $screen-mobiles) {
        font-size: 31px;
      }
    }

    &__table {
      display: grid;
      margin: calc(var(--global-space) * 3) 0;
      grid-template-rows: repeat(7, minmax(96px, max-content));

      @media (max-width: $screen-tablets) {
        display: none;
      }

      &__col {
        display: flex;
        height: 100%;
        border-width: 0px 1px 1px 0px;
        border-style: solid;
        border-color: #e2e2e2;

        &__detail {
          display: flex;
          flex-direction: column;
          margin: auto;

          &__line {
            text-align: center;
            display: flex;
            gap: calc(var(--global-space) * 0.5);
            margin: auto;

            > small {
              font-size: 12px;
              margin: auto 0;
            }
          }

          &__title {
            font-family: Anakotmai-Light;
            font-size: 16px;
          }

          &__hearder {
            font-family: Anakotmai-Medium;
            font-size: 24px;
          }

          &__subheadder {
            font-family: Anakotmai-Medium;
            font-size: 14px;

            &--small {
              font-family: Anakotmai-Medium;
              font-size: 10px;
            }
          }

          &__main {
            font-family: Anakotmai-Medium;
            font-size: 20px;
          }

          &__second {
            font-family: Anakotmai-Light;
            font-size: 16px;
          }
        }
      }

      &__row {
        display: contents;
        height: max-content;
        width: 100%;
        &:first-child,
        &:last-child {
          border: none;
          color: white;
          > .Home__pricing__table__col:nth-child(2) {
            background: var(--c-primary-1);
          }
          > .Home__pricing__table__col:nth-child(3) {
            background: var(--c-primary-2);
          }
          > .Home__pricing__table__col:nth-child(4) {
            background: var(--c-primary-3);
          }
          > .Home__pricing__table__col:nth-child(5) {
            background: var(--c-primary-4);
          }
          > .Home__pricing__table__col:nth-child(6) {
            background: var(--c-primary-5);
          }
          > .Home__pricing__table__col:nth-child(7) {
            background: var(--c-primary-6);
          }
        }

        &:first-child {
          > .Home__pricing__table__col:nth-child(n) {
            border-radius: 16px 16px 0px 0px;
            border: none;
          }
        }

        &:last-child {
          > .Home__pricing__table__col:nth-child(1) {
            color: #2b2b2b;
          }
        }
      }
    }

    .PackagesIndex__plans__dl {
      padding-bottom: calc(var(--global-space) * 3);
      grid-template-columns: 1fr;
      grid-gap: calc(var(--global-space) * 1);
      justify-items: center;
      display: none;

      @media (max-width: $screen-tablets) {
        display: grid;
        width: 100%;
      }

      .PackagesIndex__plans__dl__item {
        width: 100%;
      }
    }
  }

  &__contact {
    box-shadow: inset 0 9px 0 0 #ff8f3a;
    padding: calc(var(--global-space) * 7) 0;

    @media (max-width: $screen-tablets) {
      padding: calc(var(--global-space) * 3) 0;
    }

    > .container {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: calc(var(--global-space) * 7);
      align-items: center;

      @media (max-width: $screen-tablets-l) {
        grid-gap: calc(var(--global-space) * 3);
      }

      @media (max-width: $screen-tablets) {
        grid-template-columns: 1fr;
      }
    }

    &__img {
      justify-self: center;
    }

    &__right {
      @media (max-width: $screen-tablets) {
        grid-row: 1 / 2;
        display: grid;
        justify-items: center;
        text-align: center;
      }

      &__h1 {
        @extend .NavLanding__h1__a;
        width: 160px;
        height: 64px;
      }

      &__strong {
        display: block;
        font-family: Anakotmai-Medium;
        font-size: 20px;
        color: #2b2b2b;
        margin-top: calc(var(--global-space) * 1);
        margin-bottom: calc(var(--global-space) * 0.5);
      }

      &__p {
        font-family: Anakotmai-Light;
        font-size: 20px;
        color: #2b2b2b;
        margin-bottom: calc(var(--global-space) * 1.5);

        &:first-of-type {
          br {
            @media (max-width: $screen-tablets-l) {
              display: none;
            }
          }
        }
      }

      &__socials {
        display: grid;
        grid-template-columns: repeat(3, max-content);
        grid-gap: calc(var(--global-space) * 1);
        align-items: center;
        margin-bottom: calc(var(--global-space) * 2);
      }

      &__image {
        display: grid;
        justify-content: start;
      }

      &__img {
        width: 200px;
      }

      &__small {
        display: block;
        color: #ee2e24;
        font-family: Anakotmai-Medium;
        text-align: center;
        font-size: 12px;
        margin-top: calc(var(--global-space) * 0.25);
      }
    }
  }
}

.Buttons {
  display: grid;
  justify-content: center;

  &--jc-s {
    justify-content: start;
  }
}

.ButtonLanding {
  background-color: #00aefd;
  box-shadow: inset 0 -5px 0 0 #009de6;
  border-radius: 8px;
  padding: calc(var(--global-space) * 1) calc(var(--global-space) * 1.5)
    calc(var(--global-space) * 1);
  border: none;
  display: block;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: Anakotmai-Bold;
  font-size: 24px;
  color: #ffffff;
  text-align: center;

  @media (max-width: $screen-mobiles-s) {
    font-size: 20px;
  }

  &--orange {
    background-color: #ff8f3a;
    box-shadow: inset 0 -5px 0 0 #ee761b;
  }

  br {
    display: none;

    @media (max-width: $screen-mobiles) {
      display: block;
    }
  }
}

.Footer {
  box-shadow: inset 0 1px 0 0 #e8e8e8;
  padding: calc(var(--global-space) * 1) 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #888888;

  @media (max-width: $screen-mobiles-l) {
    text-align: center;
  }

  > .container {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: calc(var(--global-space) * 1);
    align-items: baseline;
    justify-content: space-between;

    @media (max-width: $screen-mobiles-l) {
      grid-template-columns: 1fr;
    }
  }

  strong {
    font-family: 'Roboto', sans-serif;
  }

  &__links {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: calc(var(--global-space) * 1);
    align-items: baseline;

    @media (max-width: $screen-mobiles-l) {
      grid-row: 1 / 2;
      justify-content: center;
    }

    @media (max-width: $screen-mobiles-s) {
      grid-template-columns: max-content;
    }

    &__a {
      &:last-child {
        @media (max-width: $screen-mobiles-s) {
          grid-row: 1 / 2;
        }
      }
    }
  }
}

.Anchor {
  transform: translateY(calc(var(--NavLanding-h) * -1));
}

.Layout {
  display: grid;
  grid-template-rows: max-content max-content 1fr max-content;
  min-height: 100vh;

  --app-width: 1280px;
}

.Nav {
  > .container {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-gap: calc(var(--global-space) * 1);
    align-items: center;
    min-height: 64px;
  }

  &__h1 {
    &__a {
      display: block;
      width: 88px;
      height: 35px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(image-path('app/Nav__h1__a.svg'));
      text-indent: -99999px;
      font-size: 0;
    }
  }

  &__links {
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: calc(var(--global-space) * 1);
    margin-left: calc(var(--global-space) * 1);

    &__a {
      font-family: Anakotmai-Medium;
      font-size: 14px;
      color: #2b2b2b;
      padding: calc(var(--global-space) * 1) calc(var(--global-space) * 1.5);

      &--active {
        background-color: #00aefd;
        color: #fff;
        font-family: Anakotmai-Bold;
      }
    }
  }

  &__profile {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: calc(var(--global-space) * 0.5);
    align-items: center;
    position: relative;

    --Nav__profile__panel-o: 0;
    --Nav__profile__panel-pe: none;
    --Nav__profile__panel-tf: translateY(-4px);

    &:hover {
      --Nav__profile__panel-o: 1;
      --Nav__profile__panel-pe: auto;
      --Nav__profile__panel-tf: none;
    }

    &__left {
      display: grid;
    }

    &__span {
      font-family: Anakotmai-Medium;
      font-size: 14px;
      color: #2b2b2b;
      text-align: right;
    }

    &__small {
      font-family: Anakotmai-Bold;
      font-size: 10px;
      color: #888888;
      text-align: right;
    }

    &__img {
      height: 40px;
      width: 40px;
      object-fit: cover;
      border: 1px solid #e8e8e8;
      border-radius: 50%;
      background-color: whitesmoke;
    }

    &__panel {
      opacity: var(--Nav__profile__panel-o);
      pointer-events: var(--Nav__profile__panel-pe);
      transform: var(--Nav__profile__panel-tf);
      transition: opacity 0.2s, transform 0.2s;
      will-change: opacity, transform;
      position: absolute;
      top: 100%;
      right: 0;
      padding-top: calc(var(--global-space) * 0.75);

      &__wrapper {
        background-color: #ffffff;
        border: 1px solid #e8e8e8;
        border-radius: 4px;
        padding: calc(var(--global-space) * 1);
      }

      &__links {
        display: grid;
        grid-gap: calc(var(--global-space) * 0.5);

        &__a {
          font-family: Anakotmai-Medium;
          font-size: 14px;
          color: #2b2b2b;
          white-space: nowrap;

          &:hover {
            text-decoration: underline;
          }

          &--red {
            color: #f97272;
          }
        }
      }
    }
  }

  .Button--line {
    font-size: 12px;
    padding: calc(var(--global-space) * 0.25) calc(var(--global-space) * 1)
      calc(var(--global-space) * 0.25) calc(var(--global-space) * 0.5);
    gap: calc(var(--global-space) * 0.5);

    &::before {
      width: 30px;
      height: 30px;
    }
  }
}

.Main {
  background-color: #f8f8f8;
  padding: calc(var(--global-space) * 2);

  > .container {
    height: 100%;
  }
}

.Header {
  // display: grid;
  // grid-template-columns: repeat(4, max-content);
  // grid-gap: calc(var(--global-space) * 1);
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: calc(var(--global-space) * 1);

  &__breadcrumb {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: calc(var(--global-space) * 1);

    > * {
      &:not(:first-child) {
        display: grid;
        grid-template-columns: max-content max-content;
        grid-gap: calc(var(--global-space) * 1);
        align-items: center;

        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 12px;
          background-image: url(image-path('app/Header__breadcrumb__a.svg'));
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }

    &__a {
      display: block;
      font-family: Anakotmai-Light;
      font-size: 24px;
      color: #2b2b2b;
    }

    &__current {
      font-family: Anakotmai-Medium;
      font-size: 24px;
      color: #2b2b2b;

      &__edit-room {
        display: grid;
        grid-template-columns: max-content max-content;
        grid-gap: calc(var(--global-space) * 0.75);
        align-items: center;
      }
    }
  }

  &__key {
    background-color: #e8e8e8;
    border-radius: 4px;
    font-family: Anakotmai-Medium;
    font-size: 14px;
    color: #888888;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: calc(var(--global-space) * 0.25);
    padding: calc(var(--global-space) * 0.25) calc(var(--global-space) * 0.5);
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 17px;
      height: 16px;
      background-image: url(image-path('app/Header__key.svg'));
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  .Button {
    padding: calc(var(--global-space) * 0.5) calc(var(--global-space));
    font-family: Anakotmai-Bold;
    line-height: 1;
    display: grid;
    grid-template-columns: 18px 1fr;
    gap: 8px;
    align-items: center;

    &--with-share {
      margin-left: auto;

      &::before {
        content: '';
        width: 18px;
        height: 20px;
        background: no-repeat center/contain
          url(image-path('app/Header__button__sharing.svg'));
      }
    }

    &--download {
      margin-right: calc(var(--global-space) * 0.4);
      height: 40px;

      &::before {
        content: '';
        width: 19px;
        height: 18px;
        background: no-repeat center/contain
          url(image-path('app/Header__button__download.svg'));
      }
    }

    &--upload {
      margin-left: auto;
      margin-right: calc(var(--global-space) * 0.4);
      height: 40px;

      &::before {
        content: '';
        width: 21px;
        height: 12px;
        background: no-repeat center/contain
          url(image-path('app/Header__button__upload.svg'));
      }
    }
  }

  &__button-wrapper {
    margin-left: auto;
    display: flex;
  }
}

.Table {
  background-color: #fff;
  display: grid;

  --Table__col-boxs: inset 0 -1px 0 0 #e8e8e8;

  &__row {
    display: contents;

    &:last-child {
      --Table__col-boxs: none;
    }
  }

  &__col {
    font-family: Anakotmai-Medium;
    font-size: 14px;
    color: #2b2b2b;
    padding: 0 calc(var(--global-space) * 1);
    box-shadow: var(--Table__col-boxs);
    min-height: 56px;
    display: grid;
    align-items: center;
    justify-content: start;
    overflow: hidden;

    &--header {
      display: flex;
      font-family: Anakotmai-Bold;
      font-size: 14px;
      color: #888888;
      justify-content: space-between;
      gap: calc(var(--global-space) * 1);
    }

    &__a {
      color: #00aefd;
    }

    &__chatroom-name {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: calc(var(--global-space) * 0.5);
      align-items: center;

      &__edit {
        &::before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background-image: url(image-path(
            'app/Table__col__chatroom-name__a.svg'
          ));
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }

      &__span {
        color: #cccccc;
      }
    }

    &__enter-chat-room {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: calc(var(--global-space) * 0.5);
      align-items: center;
      font-family: Anakotmai-Medium;
      font-size: 14px;
      color: #00aefd;

      &::before {
        content: '';
        display: block;
        width: 20px;
        height: 19px;
        background-image: url(image-path(
          'app/Table__col__enter-chat-room.svg'
        ));
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }

    &__plate {
      padding: calc(var(--global-space) * 0.45);
      padding-bottom: calc(var(--global-space) * 0.35);
      background-color: #e8e8e8;
      border-radius: 4px;
      line-height: 1;
      font-family: Anakotmai-Medium;
      font-size: 14px;
      color: #2b2b2b;

      &--lightgray {
        background-color: #f8f8f8;
        font-family: Anakotmai-Bold;
        color: #888888;
      }

      &--lightblue {
        background-color: #9aceff;
        font-family: Anakotmai-Bold;
        color: #fff;
      }

      &--pink {
        background-color: #ffa6d6;
        font-family: Anakotmai-Bold;
        color: #fff;
      }
    }

    &__span {
      &--red {
        color: #f97272;
      }
    }

    &__controls {
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: calc(var(--global-space) * 1);

      &__a {
        font-family: Anakotmai-Medium;
        font-size: 14px;
        color: #00aefd;

        &--red {
          color: #f97272;
        }

        strong {
          font-family: Anakotmai-Bold;
        }
      }
    }

    &__preview {
      width: 30px;
      &:hover {
        img {
          transform: scale(5);
        }
      }
    }

    &__note {
      display: grid;
      grid-template-columns: max-content minmax(100px, 1fr);
      grid-gap: calc(var(--global-space) * 0.5);
      align-items: center;
      box-shadow: var(--Table__col-boxs);
      font-family: Anakotmai-Medium;
      font-size: 14px;
      color: #2b2b2b;
      &__edit {
        &::before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background-image: url(image-path(
            'app/Table__col__chatroom-name__a.svg'
          ));
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          margin-top: -4px;
        }
      }

      &__span {
        color: #cccccc;
      }

      &__text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: default;

        .tooltiptext {
          visibility: hidden;
          display: inline-block;
          background-color: rgba(0, 0, 0, 0.64);
          color: #fff;
          text-align: center;
          padding: 5px;
          border-radius: 6px;
          min-width: 1fr;
          max-width: 150px;
          /* Position the tooltip text - see examples below! */
          position: absolute;
          z-index: 1;
          transform: translateY(-100%);
        }

        .tooltiptext::before {
          content: attr(data-tooltip);
          height: auto;
          width: auto;
          overflow: hidden;
          text-overflow: ellipsis;
          // white-space: nowrap;
          white-space: normal;
          // word-break: break-word;
          overflow-wrap: break-word;
        }

        .tooltiptext::after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: rgba(0, 0, 0, 0.64) transparent transparent transparent;
        }

        &:hover {
          .tooltiptext {
            visibility: visible;
          }
        }
      }
    }

    &--flex {
      display: flex;
      justify-content: space-between;
      gap: calc(var(--global-space) * 1);
    }
  }
}

.Placeholder {
  background-color: #ffffff;
  padding: calc(var(--global-space) * 6) calc(var(--global-space) * 1);

  &__h6 {
    font-family: Anakotmai-Medium;
    font-size: 24px;
    color: #cccccc;
    text-align: center;
  }

  &__p {
    font-family: Anakotmai-Light;
    font-size: 16px;
    color: #cccccc;
    text-align: center;
  }
}

.Modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
  will-change: opacity;
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  padding: calc(var(--global-space) * 1);
  transition: opacity 0.2s;

  &--active {
    opacity: 1;
    pointer-events: auto;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.64);
  }

  &__wrapper {
    position: relative;
    max-width: 550px;
    margin: 10vh auto;
    background-color: #ffffff;
    border-radius: 8px;
    padding: calc(var(--global-space) * 1.5);
  }

  &__x {
    position: absolute;
    top: calc(var(--global-space) * 0.5);
    right: calc(var(--global-space) * 0.5);
    box-sizing: content-box;
    padding: calc(var(--global-space) * 0.75);

    &::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      background-image: url(image-path('app/Modal__x.svg'));
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &__form {
    display: grid;
    grid-gap: calc(var(--global-space) * 1);

    .Button {
      &:not(:last-child) {
        margin-bottom: calc(var(--global-space) * -0.5);
      }
    }
  }

  &__h2 {
    font-family: Anakotmai-Bold;
    font-size: 24px;
    color: #2b2b2b;
    padding-right: calc(var(--global-space) * 1.5);

    + .Modal__p {
      margin-top: calc(var(--global-space) * -0.5);
    }
  }

  &__p {
    font-family: Anakotmai-Light;
    font-size: 14px;
    color: #2b2b2b;
  }

  &__error {
    color: #f97272;
  }
}

.Form {
  padding: calc(var(--global-space) * 2) calc(var(--global-space) * 3);
  display: grid;
  grid-gap: calc(var(--global-space) * 1);

  &__label {
    &__span {
      font-family: Anakotmai-Medium;
      font-size: 14px;
      color: #2b2b2b;
      margin-bottom: calc(var(--global-space) * 0.5);
      display: block;

      &--with-tooltip {
        display: flex;
        align-items: center;
      }

      &__optional {
        color: #b2b2b2;
      }

      &__tooltip {
        margin-left: calc(var(--global-space) * 0.5);
        position: relative;
        transform: translateY(-1px);

        --FormsNew__body__form__label__span__tooltip__panel-o: 0;
        --FormsNew__body__form__label__span__tooltip__panel-tf: translateX(
          -4px
        );
        --FormsNew__body__form__label__span__tooltip__panel-pe: none;

        &:hover {
          --FormsNew__body__form__label__span__tooltip__panel-o: 1;
          --FormsNew__body__form__label__span__tooltip__panel-tf: none;
          --FormsNew__body__form__label__span__tooltip__panel-pe: auto;
        }

        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 14px;
          background-image: url(image-path(
            'app/FormsNew__body__form__label__span__tooltip.svg'
          ));
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
        }

        &__panel {
          pointer-events: var(
            --FormsNew__body__form__label__span__tooltip__panel-pe
          );
          opacity: var(--FormsNew__body__form__label__span__tooltip__panel-o);
          transform: var(
            --FormsNew__body__form__label__span__tooltip__panel-tf
          );
          transition: opacity 0.2s, transform 0.2s;
          will-change: opacity, transform;
          top: 0;
          left: calc(100% + calc(var(--global-space) * 0.5));
          width: 240px;
          position: absolute;
          background-color: #ffffff;
          border: 1px solid #f5a679;
          box-shadow: 0 0 4px 0 #f5a679;
          border-radius: 4px;
          padding: calc(var(--global-space) * 0.5);
          font-family: Anakotmai-Medium;
          font-size: 12px;
          color: #2b2b2b;
        }
      }
    }

    &__a {
      font-family: Anakotmai-Medium;
      color: #00aefd;
      text-decoration: underline;
    }

    &__row {
      display: grid;
      grid-gap: calc(var(--global-space) * 1);
      grid-template-columns: 1fr max-content;
      align-items: center;
      margin-bottom: calc(var(--global-space) * 0.5);

      > * {
        margin-bottom: 0;
      }
    }
  }

  &__submit {
    margin-top: calc(var(--global-space) * 1);
    background-color: #00aefd;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: calc(var(--global-space) * 0.75);
    width: 100%;
    display: block;
    font-family: Anakotmai-Bold;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
  }

  &__p {
    font-family: Anakotmai-Medium;
    font-size: 12px;
    color: #888888;
    text-align: center;

    a {
      text-decoration: underline;
    }
  }

  &__errors {
    list-style-type: disc;
    padding-left: calc(var(--global-space) * 1);
    display: grid;
    grid-gap: calc(var(--global-space) * 0.5);

    &__li {
      font-family: Anakotmai-Medium;
      font-size: 14px;
      color: #f97272;
    }

    &__complete {
      font-family: Anakotmai-Medium;
      font-size: 14px;
      color: #00b900;
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: calc(var(--global-space) * 1);
  }
}

.Input,
.InputFile,
.Select {
  display: block;
  width: 100%;
  padding: calc(var(--global-space) * 0.75);
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  background-color: #fff;
  border-radius: 0;
  border: 1px solid #e8e8e8;
  font-family: Anakotmai-Medium;
  font-size: 14px;
  color: #2b2b2b;
  resize: vertical;

  &::placeholder {
    color: #b2b2b2;
  }

  &--error {
    box-shadow: 0 0 0px 1px #f97272;
  }
}

.Input {
  &:focus-within {
    outline: auto 5px -webkit-focus-ring-color;
  }

  &:disabled {
    background-color: #e8e8e8;
  }

  &--calendar {
    background-position: calc(var(--global-space) * 0.75) center;
    background-size: 16px;
    background-repeat: no-repeat;
    background-image: url(image-path('app/Input--calendar.svg'));
    padding-left: calc(var(--global-space) * 2.25);
  }

  &--search {
    background-position: calc(var(--global-space) * 0.75) center;
    background-size: 16px;
    background-repeat: no-repeat;
    background-image: url(image-path('app/Input--search.svg'));
    padding-left: calc(var(--global-space) * 2.25);
  }

  &--with-prefix {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-gap: calc(var(--global-space) * 0.5);
  }

  &--with-suffix {
    display: grid;
    grid-template-columns: 1fr max-content;
    grid-gap: calc(var(--global-space) * 0.5);
  }

  &__prefix {
    font-family: Anakotmai-Medium;
    font-size: 14px;
    color: #b2b2b2;
  }

  &__suffix {
    font-family: Anakotmai-Medium;
    font-size: 14px;
    color: #b2b2b2;
    border-left: 1px solid #e8e8e8;
    margin-top: calc(var(--global-space) * -0.75);
    margin-bottom: calc(var(--global-space) * -0.75);
    display: grid;
    align-items: center;
    padding-left: calc(var(--global-space) * 0.75);
  }

  &__input {
    display: block;
    width: 100%;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    outline: none;
    padding: 0;
    background-color: transparent;
    border: none;
    font-family: Anakotmai-Medium;
    font-size: 14px;
    color: #2b2b2b;
    resize: vertical;

    &::placeholder {
      color: #b2b2b2;
    }
  }
}

.InputFile {
  padding: 0;
  display: grid;
  grid-template-columns: 1fr max-content;
  cursor: pointer;

  &__p {
    padding: calc(var(--global-space) * 0.75);
    color: #b2b2b2;

    &--active {
      color: #2b2b2b;
    }
  }

  &__a {
    background-color: #00aefd;
    border: none;
    border-left: 1px solid #e8e8e8;
    font-family: Anakotmai-Bold;
    font-size: 14px;
    color: #ffffff;
    padding: calc(var(--global-space) * 0.5) calc(var(--global-space) * 1.5);
    display: grid;
    align-items: center;
  }

  input[type='file'] {
    display: none;
  }
}

.Select {
  background-position: calc(100% - calc(var(--global-space) * 1)) center;
  background-size: 10px 7px;
  background-repeat: no-repeat;
  background-image: url(image-path('app/Select.svg'));
  padding-right: calc(var(--global-space) * 2.5);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  resize: none;
}

.Button {
  display: block;
  text-align: center;
  background-color: #00aefd;
  border-radius: 4px;
  font-family: Anakotmai-Bold;
  font-size: 14px;
  color: #ffffff;
  padding: calc(var(--global-space) * 0.6) calc(var(--global-space) * 1.5);
  padding-bottom: calc(var(--global-space) * 0.45);
  border: none;

  &--large {
    padding-top: calc(var(--global-space) * 1);
    padding-bottom: calc(var(--global-space) * 1);
  }

  &--trans {
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px #e8e8e8;
    font-family: Anakotmai-Medium;
    color: #2b2b2b;
  }

  &--trans-blue {
    background-color: #ffffff;
    box-shadow: inset 0 0 0 1px #00aefd;
    font-family: Anakotmai-Medium;
    color: #00aefd;
  }

  &--gray {
    background-color: #808080;
    color: #ffffff;
  }

  &--red {
    background-color: #f97272;

    &--alt {
      color: #f97272;
      background-color: rgba(249, 114, 114, 0.12);
      border: 1px solid #f97272;
    }
  }

  &--green {
    background-color: #00b900;
  }

  &--orange {
    background-color: #ff8f3a;

    &--alt {
      color: #ff8f3a;
      background-color: rgba(255, 143, 58, 0.12);
      border: 1px solid #ff8f3a;
    }
  }

  &--line {
    background-color: #00b900;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: calc(var(--global-space) * 1);
    align-items: center;
    padding: calc(var(--global-space) * 0.5) calc(var(--global-space) * 1.5);
    padding-left: calc(var(--global-space) * 1);
    font-size: 16px;

    &::before {
      content: '';
      display: block;
      width: 50px;
      height: 50px;
      background-image: url(image-path('app/Button--line.png'));
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &--ghost {
    background-color: white;
    color: #2b2b2b;

    &:hover {
      background-color: #e6e6e6;
    }
  }
}

.Badge {
  line-height: 1;
  font-family: Anakotmai-Medium;
  font-size: 12px;
  display: grid;
  grid-template-columns: 8px max-content;
  gap: 8px;
  align-items: center;

  // for position in tabs component
  justify-self: end;
  align-self: center;
  grid-column: -1;
  margin-right: calc(var(--global-space) * 0.8);

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #00b900;
  }

  &--gray {
    color: #cccccc;

    &::before {
      background-color: #f8f8f8;
    }
  }

  &--red {
    &::before {
      background-color: #f97272;
    }
  }
}

.Tabs {
  background-color: #ffffff;
  display: grid;
  grid-template-columns: repeat(6, max-content);
  grid-gap: calc(var(--global-space) * 1);
  box-shadow: inset 0 -1px 0 0 #e8e8e8;

  &__a {
    font-family: Anakotmai-Medium;
    font-size: 14px;
    color: #2b2b2b;
    display: flex;
    align-items: center;
    padding: calc(var(--global-space) * 1) calc(var(--global-space) * 1.5);
    text-decoration: none !important;

    --Tabs__a__span-bgc: #cccccc;

    &--active {
      box-shadow: inset 0 -4px 0 0 #00aefd;
      color: #00aefd;
      --Tabs__a__span-bgc: #00aefd;
    }

    &--noti {
      &::after {
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        margin-left: calc(var(--global-space) * 0.4);
        border-radius: calc(var(--global-space) * 0.2);
        background-color: #f97272;
      }
    }

    &__span {
      margin-left: calc(var(--global-space) * 0.5);
      background-color: var(--Tabs__a__span-bgc);
      border-radius: 12px;
      font-family: Anakotmai-Bold;
      font-size: 12px;
      color: #ffffff;
      padding: calc(var(--global-space) * 0.25);
      padding-bottom: calc(var(--global-space) * 0.2);
      line-height: 1;
    }
  }
}

.Messages {
  background-color: #ffffff;
  padding: calc(var(--global-space) * 1);
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: max-content 1fr;
  grid-gap: calc(var(--global-space) * 1);

  --Messages__wrapper-gc: 1 / 3;
  --Messages__search-results-d: none;

  &--searching {
    --Messages__wrapper-gc: 1 / 2;
    --Messages__search-results-d: grid;
  }

  &__info {
    color: #ff8f3a;
    font-family: Anakotmai-Medium;
    align-self: center;
    justify-self: end;
    display: grid;
    grid-template-columns: 16px max-content;
    gap: calc(var(--global-space) * 0.4);
    align-items: center;

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      background: no-repeat center/contain
        url(image-path('app/Messages__info.svg'));
    }
  }

  &__search {
    display: grid;
    grid-template-columns: max-content max-content max-content;
    grid-gap: calc(var(--global-space) * 0.5);
    justify-content: end;
    grid-column: 2 / 3;

    .Input--search {
      padding-top: calc(var(--global-space) * 0.25);
      padding-bottom: calc(var(--global-space) * 0.25);
      border-radius: 4px;
      width: 280px;
    }
  }

  &__wrapper {
    background-color: #fbfbfb;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    padding: 0 calc(var(--global-space) * 1);
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    display: grid;
    position: relative;
    grid-column: var(--Messages__wrapper-gc);
    grid-row: 2 / 3;
    opacity: 0;
    pointer-events: none;

    &--active {
      opacity: 1;
      pointer-events: auto;
    }

    > .Placeholder {
      background-color: transparent;
      align-self: center;
      text-align: center;
    }
  }

  &__dl {
    display: grid;
    align-content: end;
    grid-gap: calc(var(--global-space) * 1.5);
    opacity: 0;
    transition: opacity 0.2s;
    transition-timing-function: ease-in-out;
    will-change: opacity;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    padding: calc(var(--global-space) * 1);

    &--active {
      opacity: 1;
    }

    &__item {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: calc(var(--global-space) * 1);

      --Messages__dl__item__img-gc: 1 / 2;
      --Messages__dl__item__content-gc: 2 / 3;
      --Messages__dl__item__content-jc: start;
      --Messages__dl__item__dd-js: start;
      --Messages__dl__item__time-ta: right;

      &--self {
        grid-template-columns: 1fr max-content;
        text-align: right;

        --Messages__dl__item__img-gc: 2 / 3;
        --Messages__dl__item__content-gc: 1 / 2;
        --Messages__dl__item__content-jc: end;
        --Messages__dl__item__dd-js: end;
        --Messages__dl__item__time-ta: left;
      }

      &--highlighted {
        dd {
          background-color: rgba(0, 174, 253, 0.12);
          border-color: #00aefd;
          animation-fill-mode: forwards;
          animation-name: ani-Messages__dl__item__dd--highlighted;
          animation-duration: 400ms;
          animation-timing-function: ease-in-out;
        }
      }

      &__img {
        box-shadow: 0 0 0 1px #e8e8e8;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        grid-column: var(--Messages__dl__item__img-gc);
      }

      &__content {
        display: grid;
        grid-gap: calc(var(--global-space) * 0.5);
        grid-column: var(--Messages__dl__item__content-gc);
        grid-row: 1 / 2;
        justify-content: var(--Messages__dl__item__content-jc);
      }

      &__dt {
        font-family: Anakotmai-Bold;
        font-size: 12px;
        color: #2b2b2b;
      }

      &__dd {
        text-align: left;
        font-family: Anakotmai-Medium;
        font-size: 16px;
        color: #2b2b2b;
        background-color: #ffffff;
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        padding: calc(var(--global-space) * 1);
        justify-self: var(--Messages__dl__item__dd-js);
        max-width: 600px;

        @keyframes ani-Messages__dl__item__dd--highlighted {
          0% {
            transform: none;
          }

          25% {
            transform: translateX(calc(var(--global-space) * -0.25));
          }

          75% {
            transform: translateX(calc(var(--global-space) * 0.25));
          }
        }

        &__span {
          strong {
            font-family: Anakotmai-Medium;
            color: #00aefd;
          }
        }

        &__sticker {
          max-width: 160px;
        }

        &__sticker-inline {
          display: inline-grid;
          height: 32px;
          vertical-align: middle;
        }

        &__image,
        &__video {
          max-width: 300px;
        }

        &__file {
          color: #00aefd;
        }

        &__location {
          &__iframe {
            width: 100%;
            min-width: 300px;
            height: 300px;
            margin-top: calc(var(--global-space) * 0.5);
          }
        }
      }

      &__time {
        font-family: Anakotmai-Light;
        font-size: 10px;
        color: #cccccc;
        text-align: var(--Messages__dl__item__time-ta);
      }
    }
  }

  &__search-results {
    background-color: #fbfbfb;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    display: var(--Messages__search-results-d);
    grid-template-rows: max-content 1fr;

    > .Placeholder {
      background-color: transparent;
      align-self: center;
      text-align: center;
      grid-row: 1 / 3;
    }

    &__header {
      padding: calc(var(--global-space) * 1);
      box-shadow: inset 0 -1px 0 0 #e8e8e8;
      display: grid;
      grid-template-columns: 1fr max-content max-content;
      align-items: center;

      &__h4 {
        font-family: Anakotmai-Medium;
        font-size: 14px;
        color: #2b2b2b;
        margin-right: calc(var(--global-space) * 1);
      }

      &__a {
        background-color: #2b2b2b;
        border-radius: 4px;
        width: 40px;
        height: 40px;
        margin-left: calc(var(--global-space) * 0.25);
        display: grid;
        align-items: center;
        justify-content: center;

        &::before {
          content: '';
          display: block;
          width: 14px;
          height: 8px;
          background-image: url(image-path(
            'app/Messages__search-results__header__a.svg'
          ));
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
        }

        &--prev::before {
          transform: scaleY(-1);
        }
      }
    }

    &__wrapper {
      position: relative;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &__dl {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      display: grid;
      grid-gap: calc(var(--global-space) * 0.25);
      padding: calc(var(--global-space) * 0.5);

      &__item {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-gap: calc(var(--global-space) * 1);
        border-radius: 4px;
        padding: calc(var(--global-space) * 0.5);
        transition: background-color 0.2s, box-shadow 0.2s;
        transition-timing-function: ease-in-out;
        will-change: background-color, box-shadow;

        &--active {
          background-color: rgba(0, 174, 253, 0.12);
          box-shadow: inset 0 0 0 1px #00aefd;
        }

        &__img {
          box-shadow: 0 0 0 1px #e8e8e8;
          width: 48px;
          height: 48px;
          border-radius: 50%;
        }

        &__content {
          display: grid;
          grid-template-columns: 1fr max-content;
          grid-gap: calc(var(--global-space) * 0.25)
            calc(var(--global-space) * 1);
        }

        &__dt {
          font-family: Anakotmai-Bold;
          font-size: 12px;
          color: #2b2b2b;
        }

        &__time {
          font-family: Anakotmai-Light;
          font-size: 12px;
          color: #cccccc;
        }

        &__dd {
          font-family: Anakotmai-Light;
          font-size: 12px;
          color: #2b2b2b;
          grid-column: 1 / 3;

          strong {
            font-family: Anakotmai-Medium;
            color: #00aefd;
          }
        }
      }
    }
  }
}

.Files {
  &__search {
    @extend .Messages__search;

    position: relative;
    grid-template-columns: repeat(4, max-content);
    padding: calc(var(--global-space) * 0.8);
    background-color: white;
    border-bottom: 1px solid #e8e8e8;

    .Calendar {
      left: 300px;
    }

    .Input {
      padding: calc(var(--global-space) * 0.5);
      padding-left: calc(var(--global-space) * 2.25);
      &--search {
        width: auto;
      }

      &--calendar {
        width: 275px;
      }
    }

    .Button {
      padding: calc(var(--global-space) * 0.475) calc(var(--global-space) * 0.8);
    }
  }
}

.SourcesShow {
  height: 100%;
  display: grid;
  grid-template-rows: max-content max-content 1fr;
}

.LoadMore {
  text-align: center;
  padding: calc(var(--global-space) * 0.5);
}

.ProfilesShow {
  --app-width: 620px;

  &__form {
    padding: calc(var(--global-space) * 2) calc(var(--global-space) * 4);
    background-color: #fff;
    margin-top: calc(var(--global-space) * 1);
    background-color: #ffffff;
    border-radius: 8px;
    display: grid;
    grid-gap: calc(var(--global-space) * 1);

    &__img {
      border: 1px solid #e8e8e8;
      width: 116px;
      height: 116px;
      border-radius: 50%;
      justify-self: center;
    }

    > .Button {
      width: 100%;
    }
  }

  &__resend-email {
    font-family: Anakotmai-Light;
    font-size: 16px;
    text-align: center;
    color: #2b2b2b;
    padding: calc(var(--global-space) * 0.5) calc(var(--global-space) * 1);
    margin-top: calc(var(--global-space) * 0.25);

    a {
      font-family: Anakotmai-Bold;
      color: #00aefd;
    }
  }

  &__consent-form {
    &__wrapper {
      font-family: Anakotmai-Medium;
      margin-bottom: calc(var(--global-space) * 1.6);
    }

    .Button {
      width: 100%;
    }

    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      z-index: -1;
    }

    label {
      position: relative;
      display: inline-block;
      padding-left: 40px;
      line-height: 26px;
      cursor: pointer;

      &::before,
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 24px;
        height: 24px;
      }

      &::before {
        content: '';
        border-radius: 4px;
        border: solid 1px #e8e8e8;
        background-color: #ffffff;
      }

      &::after {
        background: no-repeat center/contain
          url(image-path('app/ProfilesShow__checked.svg'));
      }
    }

    input[type='checkbox']:checked + label::after {
      content: '';
    }
  }
}

.Guide {
  background-color: #00aefd;
  border-radius: 8px;
  padding: calc(var(--global-space) * 1);
  margin-bottom: calc(var(--global-space) * 2);

  --Guide__content-d: grid;
  --Guide__open-d: none;

  &--hidden {
    --Guide__content-d: none;
    --Guide__open-d: grid;
    margin-bottom: calc(var(--global-space) * 1);
  }

  &__header {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: calc(var(--global-space) * 1);
    justify-content: space-between;
    align-items: center;
  }

  &__h3 {
    font-family: Anakotmai-Medium;
    font-size: 18px;
    color: #ffffff;
  }

  &__close {
    font-family: Anakotmai-Medium;
    font-size: 12px;
    color: #0084c0;
    display: var(--Guide__content-d);
    grid-template-columns: max-content max-content;
    grid-gap: calc(var(--global-space) * 0.5);
    align-items: center;

    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-image: url(image-path('app/Guide__close.svg'));
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &__open {
    display: var(--Guide__open-d);
    align-items: center;
    padding: calc(var(--global-space) * 0.25) calc(var(--global-space) * 0.5);
    transform: translateX(calc(var(--global-space) * 0.5));

    &::before {
      content: '';
      display: block;
      width: 14px;
      height: 8px;
      background-image: url(image-path('app/Guide__open.svg'));
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &__content {
    display: var(--Guide__content-d);
    grid-template-columns: 1fr max-content;
    grid-gap: calc(var(--global-space) * 3);
    margin-top: calc(var(--global-space) * 1);
    align-items: center;

    &::after {
      transform: translateY(calc(var(--global-space) * 2));
      content: '';
      display: block;
      width: 188px;
      height: 247px;
      background-image: url(image-path('app/Guide.svg'));
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }

  &__ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: calc(var(--global-space) * 1.5);

    &__li {
      display: contents;

      &__img {
        grid-row: 1 / 2;
        align-self: end;
        justify-self: center;
      }

      &__span {
        font-family: Anakotmai-Medium;
        font-size: 14px;
        color: #ffffff;
        grid-row: 2 / 3;
        text-align: center;

        code {
          display: inline-grid;
          background-color: #0084c0;
          border-radius: 4px;
          padding: calc(var(--global-space) * 0.125)
            calc(var(--global-space) * 0.25);
          font-family: Anakotmai-Medium;
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
  }
}

.Terms,
.Privacy {
  padding-top: calc(var(--global-space) * 1);
  padding-bottom: calc(var(--global-space) * 2);

  > .container {
    width: 100%;
    display: grid;
    grid-template-columns: 240px 1fr;
    align-items: start;
    grid-gap: calc(var(--global-space) * 3);
    font-family: Anakotmai-Light;

    @media (max-width: $screen-mobiles-l) {
      grid-template-columns: 1fr;
    }
  }

  aside {
    font-family: Anakotmai-Medium;
    padding-top: calc(var(--global-space) * 0.5);
    display: grid;
    grid-gap: calc(var(--global-space) * 0.75);
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--NavLanding-h) + calc(var(--global-space) * 1));
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(
      100vh - var(--NavLanding-h) - calc(var(--global-space) * 1)
    );

    @media (max-width: $screen-tablets) {
      max-height: calc(
        100vh - var(--NavLanding-h) - calc(var(--global-space) * 1) -
          var(--NavLanding__links-h)
      );
    }

    @media (max-width: $screen-mobiles-l) {
      display: none;
    }

    // For overflowing space.
    &::after {
      content: '';
      display: block;
      height: 1px;
    }
  }

  main {
    font-size: 16px;
    display: grid;
    grid-gap: calc(var(--global-space) * 1);

    h2 {
      font-size: 24px;
      font-family: Anakotmai-Medium;
    }

    h3 {
      font-size: 20px;
      font-family: Anakotmai-Medium;
    }

    h4 {
      font-size: 18px;
      font-family: Anakotmai-Medium;
    }

    ul {
      padding-left: calc(var(--global-space) * 1.5);
      list-style-type: disc;
    }
  }
}

.Session {
  background-color: #81d8ff;

  --app-width: 620px;

  > .container {
    display: grid;
    grid-template-rows: 1fr max-content;
    grid-gap: calc(var(--global-space) * 1);
    min-height: 100vh;
    width: 100%;
  }

  &__body {
    align-self: center;
  }

  &__content {
    background-color: #fff;
    border-radius: 8px;
    margin-top: calc(var(--global-space) * 2);
  }

  &__header {
    padding: calc(var(--global-space) * 2) calc(var(--global-space) * 1)
      calc(var(--global-space) * 1.5);
    border-bottom: 1px solid #e8e8e8;
  }

  &__bottom {
    padding: calc(var(--global-space) * 2) calc(var(--global-space) * 1);
    display: grid;
  }

  &__h1 {
    display: grid;
    justify-content: center;

    &__a {
      display: block;
      width: 168px;
      height: 67px;
      font-size: 0;
      text-indent: -99999px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url(image-path('app/Session__h1__a.svg'));
    }
  }

  &__h2 {
    font-family: Anakotmai-Bold;
    font-size: 24px;
    color: #2b2b2b;
    text-align: center;
  }

  &__p {
    margin-top: calc(var(--global-space) * 1);
    font-family: Anakotmai-Medium;
    font-size: 14px;
    color: #888888;
    text-align: center;
  }

  .Button {
    justify-self: center;
  }

  &__small {
    margin-top: calc(var(--global-space) * 1.5);
    display: block;
    font-family: Anakotmai-Medium;
    font-size: 12px;
    color: #888888;
    text-align: center;

    a {
      color: #00aefd;
    }

    br {
      @media (max-width: $screen-mobiles-s) {
        display: none;
      }
    }
  }

  &__footer {
    font-family: Roboto-Regular;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    padding: calc(var(--global-space) * 1);
  }
}

.MediaIndex {
  &__search {
    display: flex;
    gap: calc(var(--global-space) * 0.5);
    justify-content: space-between;
    background-color: white;
    border-bottom: 1px solid #e8e8e8;
    .Medias__search {
      @extend .Files__search;
      border-bottom: none;

      .Calendar {
        left: -258px;
      }
    }
  }

  &__filter {
    background-color: #fff;
    padding: calc(var(--global-space) * 1);
    padding-bottom: calc(var(--global-space) * 0.5);
    display: grid;
    grid-template-columns: repeat(4, max-content);
    grid-gap: calc(var(--global-space) * 0.5);

    &__a {
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: calc(var(--global-space) * 0.5);
      align-items: center;
      padding: calc(var(--global-space) * 0.5) calc(var(--global-space) * 0.5);
      text-decoration: none !important;

      --MediaIndex__filter__a__path-f: #cccccc;
      --MediaIndex__filter__a__span-c: #2b2b2b;

      &:nth-of-type(1) {
        color: #ff8f3a;
      }

      &:nth-of-type(2) {
        color: #926eed;
      }

      &:nth-of-type(3) {
        color: #fca091;
      }

      &:nth-of-type(4) {
        color: #00aefd;
        padding-right: calc(var(--global-space) * 0.75);
        padding-left: calc(var(--global-space) * 0.75);
        grid-template-columns: max-content;
      }

      &--active {
        border-color: currentColor;
        background-color: currentColor;

        --MediaIndex__filter__a__path-f: #fff;
        --MediaIndex__filter__a__span-c: #fff;
      }

      svg {
        width: inherit;

        path {
          fill: var(--MediaIndex__filter__a__path-f);
        }
      }

      &__span {
        font-family: Anakotmai-Medium;
        font-size: 14px;
        color: var(--MediaIndex__filter__a__span-c);
      }
    }
  }
}

.ImageLoader {
  height: 5px;
  width: 5px;
  display: inline-grid;
  background-color: #ccc;
}

.PackagesIndex {
  &__current-plan {
    background-color: #e8e8e8;
    padding: calc(var(--global-space) * 1.5);

    &__wrapper {
      background-color: #ffffff;
      border-radius: 8px;
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-gap: calc(var(--global-space) * 1);
    }

    &__left {
      border-radius: 8px 0 0 8px;
      background-color: #00aefd;
      padding: calc(var(--global-space) * 1);

      &__p {
        font-family: Anakotmai-Medium;
        font-size: 14px;
        color: #ffffff;
      }

      &__h3 {
        font-family: Anakotmai-Medium;
        font-size: 32px;
        color: #ffffff;
        margin-top: calc(var(--global-space) * 0.5);
      }

      &__span {
        display: block;
        font-family: Anakotmai-Medium;
        font-size: 16px;
        color: #ffffff;
        margin-top: calc(var(--global-space) * 0.5);
      }

      &__small {
        display: block;
        font-family: Anakotmai-Medium;
        font-size: 12px;
        color: #ffffff;
        margin-top: calc(var(--global-space) * 0.25);
      }
    }

    &__dl {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: center;

      &__item {
        --PackagesIndex__current-plan__dl__item__wrapper-bs: inset -1px 0 0 0 #e8e8e8;
        display: grid;
        align-items: center;

        &:last-child {
          --PackagesIndex__current-plan__dl__item__wrapper-bs: none;
        }

        &__wrapper {
          box-shadow: var(--PackagesIndex__current-plan__dl__item__wrapper-bs);
        }

        &__dd {
          font-family: Anakotmai-Light;
          font-size: 32px;
          color: #2b2b2b;

          small {
            font-family: Anakotmai-Light;
            font-size: 12px;
            color: #cccccc;
          }

          &__messages {
            &--red {
              font-family: Anakotmai-Medium;
              font-size: 32px;
              color: #f97272;
            }
          }
        }

        &__dt {
          font-family: Anakotmai-Medium;
          font-size: 20px;
          color: #2b2b2b;

          &__span {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          &__exceed {
            position: relative;
            margin-left: calc(var(--global-space) * 0.5);
            transform: translateY(-1px);

            --PackagesIndex__current-plan__dl__item__dt__exceed__panel-o: 0;
            --PackagesIndex__current-plan__dl__item__dt__exceed__panel-tf: translateX(
                -50%
              )
              translateY(-3px);

            &:hover {
              --PackagesIndex__current-plan__dl__item__dt__exceed__panel-o: 1;
              --PackagesIndex__current-plan__dl__item__dt__exceed__panel-tf: translateX(
                -50%
              );
            }

            &::before {
              content: '';
              display: block;
              width: 19px;
              height: 16px;
              background-image: url(image-path(
                'app/PackagesIndex__current-plan__dl__item__dt__exceed.svg'
              ));
              background-position: center center;
              background-size: cover;
              background-repeat: no-repeat;
            }

            &__panel {
              position: absolute;
              top: calc(100% + calc(var(--global-space) * 0.25));
              left: 50%;
              background-position: center center;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url(image-path(
                'app/PackagesIndex__current-plan__dl__item__dt__exceed__panel.svg'
              ));
              width: 208px;
              height: 58px;
              display: grid;
              align-content: end;
              opacity: var(
                --PackagesIndex__current-plan__dl__item__dt__exceed__panel-o
              );
              transform: var(
                --PackagesIndex__current-plan__dl__item__dt__exceed__panel-tf
              );
              transition: opacity 0.2s, transform 0.2s;
              transition-timing-function: ease-in-out;
              will-change: opacity, transform;

              &__span {
                font-family: Anakotmai-Medium;
                font-size: 12px;
                color: #ffffff;
                text-align: center;
                transform: translateY(-8px);
              }
            }
          }
        }

        &__small {
          margin-top: calc(var(--global-space) * 0.25);
          font-family: Anakotmai-Light;
          font-size: 14px;
          color: #2b2b2b;
          text-align: center;
        }
      }
    }
  }

  &__plans {
    background-color: #fff;
    padding: calc(var(--global-space) * 1.5);
    padding-top: calc(var(--global-space) * 2);

    &__h3 {
      font-family: Anakotmai-Medium;
      font-size: 24px;
      color: #2b2b2b;
      text-align: center;
    }

    &__dl {
      margin-top: calc(var(--global-space) * 2.5);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: calc(var(--global-space) * 1.5);

      &__item {
        --bgColor: var(--c-primary-1);

        &:nth-child(2) {
          --bgColor: var(--c-primary-2);
        }
        &:nth-child(3) {
          --bgColor: var(--c-primary-3);
        }
        &:nth-child(4) {
          --bgColor: var(--c-primary-4);
        }
        &:nth-child(5) {
          --bgColor: var(--c-primary-5);
        }
        &:nth-child(6) {
          --bgColor: var(--c-primary-6);
        }

        &__header {
          background-color: var(--bgColor);
          color: white;
          padding: 16px;
          gap: 4px;
          border-radius: 8px 8px 0px 0px;

          > * {
            font-family: Anakotmai-Medium;
          }

          &__package {
            font-size: 12px;
            text-transform: uppercase;
          }

          &__price {
            > span {
              font-family: Anakotmai-Bold;
              font-size: 24px;
            }
          }
        }

        &__details {
          padding: 16px;
          border-width: 0px 1px 1px 1px;
          border-style: solid;
          border-color: #d3d3d3;
          border-radius: 0px 0px 8px 8px;
          font-size: 14px;
          font-family: Anakotmai-Light;

          &__row {
            display: flex;
            gap: calc(var(--global-space) * 0.5);
            margin-bottom: calc(var(--global-space) * 0.5);
          }

          &__buttons {
            margin-top: calc(var(--global-space) * 1);
          }
        }
      }
    }
  }
}

.Alert {
  &__wrapper {
    background-color: #ff8f3a;
    padding: calc(var(--global-space) * 0.5);
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: calc(var(--global-space) * 1);
    justify-content: center;
    align-items: baseline;
  }

  &__p {
    font-family: Anakotmai-Medium;
    font-size: 14px;
    color: #914f1d;
  }

  &__a {
    text-align: center;
    text-decoration: underline;
    font-family: Anakotmai-Medium;
    font-size: 14px;
    color: #914f1d;
  }
}

.ConfirmationError {
  display: grid;

  > .container {
    width: 100%;
    display: grid;
    align-items: center;
  }

  &__content {
    display: grid;
    grid-gap: calc(var(--global-space) * 1);
    justify-items: center;
  }

  &__h3 {
    font-family: Anakotmai-Medium;
    font-size: 32px;
    color: #2b2b2b;
    text-align: center;
  }

  &__p {
    font-family: Anakotmai-Light;
    font-size: 20px;
    color: #2b2b2b;
    text-align: center;
  }
}

.LinksIndex {
  display: grid;
  justify-items: center;
  padding: calc(var(--global-space) * 1);

  * {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
  }

  &__p {
    font-size: 18px;
    color: #000000;
    text-align: center;
  }

  .Input {
    text-align: center;
    margin-top: calc(var(--global-space) * 1);
  }

  .ButtonLanding {
    margin-top: calc(var(--global-space) * 1);
    font-size: 16px;
    font-weight: bold;
    width: 100%;
  }

  .Form__errors {
    margin-top: calc(var(--global-space) * 1);
  }

  &__small {
    display: block;
    font-size: 14px;
    color: #9f9f9f;
    text-align: center;
    margin-top: calc(var(--global-space) * 0.75);

    a {
      color: #009de6;
    }
  }

  &__loading {
    font-size: 18px;
    color: #000000;
    text-align: center;
    padding: calc(var(--global-space) * 1);
  }
}

.MembersIndex {
  &__wrapper {
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 371px 1fr;
    min-height: 500px;
  }

  &__empty {
    font-size: 20px;
    text-align: center;
    font-family: Anakotmai-Medium;
    color: #cccccc;
    margin-top: calc(var(--global-space) * 3);
  }

  &__left {
    font-family: Anakotmai-Medium;
    border-right: 1px solid #e8e8e8;

    &__a {
      text-decoration: none !important;
    }

    &__auto-approve {
      padding: calc(var(--global-space) * 1.4) calc(var(--global-space) * 1.6);
      border-bottom: 1px solid #e8e8e8;
      display: grid;
      grid-template-columns: 1fr 46px;
      grid-template-rows: max-content max-content;
      align-items: center;

      &__p {
        grid-column: 1/2;

        &--light {
          font-family: Anakotmai-Light;
        }
      }

      .Switch {
        grid-row: 1/-1;
        grid-column: 2/3;
      }
    }

    &__menu {
      padding: calc(var(--global-space) * 1.3) calc(var(--global-space) * 1.6)
        calc(var(--global-space) * 1.25);
      border-bottom: 1px solid #e8e8e8;
      display: grid;
      grid-template-columns: max-content 1fr;
      align-items: center;
      gap: 16px;
      cursor: pointer;

      &--grant {
        background-color: #00aefd;
        color: #ffffff;
        path {
          fill: #ffffff;
        }
      }

      &--deny {
        background-color: #f97272;
        color: #ffffff;
        path {
          fill: #ffffff;
        }
      }
    }
  }

  .Table {
    .Button {
      padding: calc(var(--global-space) * 0.4) calc(var(--global-space) * 0.9);
    }
  }
}

.Switch {
  display: inline-block;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  label {
    width: 46px;
    border-radius: 30px;
    background-color: #cccccc;
    padding: calc(var(--global-space) * 0.2);
    display: flex;
    cursor: pointer;
    transition: background-color 0.2s;

    &::before {
      content: '';
      width: 20px;
      height: 20px;
      display: block;
      border-radius: 50%;
      background-color: #ffffff;
    }
  }

  input:checked + label {
    justify-content: flex-end;
    background-color: #6ac761;
  }
}

.RoomSharing {
  font-family: Anakotmai-Medium;

  .Modal__wrapper {
    padding: 0;
    display: grid;
    grid-template-columns: 417px 1fr;
    max-width: 1117px;
  }

  &__left {
    border-right: 1px solid #e8e8e8;
    padding: calc(var(--global-space) * 2.8) calc(var(--global-space) * 1.3)
      calc(var(--global-space) * 2.8) calc(var(--global-space) * 1.6);

    &__head {
      display: grid;
      grid-template-columns: 70px 1fr;
      gap: calc(var(--global-space) * 0.8);
      margin-bottom: calc(var(--global-space) * 1.3);

      &__svg {
        width: 70px;
        height: 82px;
        background: no-repeat center/contain
          url(image-path('app/RoomSharing__Left__head__svg.svg'));
      }

      &__p {
        font-family: Anakotmai-Light;
        line-height: 1.5;

        &__span {
          font-family: Anakotmai-Medium;
          font-size: 20px;
          line-height: 1;
        }
      }
    }

    &__input-wrapper {
      border: 1px solid #e8e8e8;
      padding: calc(var(--global-space) * 0.35);
      display: flex;
      align-items: center;
      margin-bottom: calc(var(--global-space) * 0.5);

      &__input {
        border: none;
        outline: none;
        flex: 1;
        font-size: 16px;
        font-family: Anakotmai-Medium;
        text-overflow: ellipsis;
        display: inline-block;
        margin-right: calc(var(--global-space) * 0.8);
      }

      .Button {
        padding: calc(var(--global-space) * 0.6);
        line-height: 1;
      }
    }

    &__bottom {
      padding-bottom: calc(var(--global-space) * 0.8);

      &__p {
        font-family: Anakotmai-Light;
      }

      &__a {
        color: #00aefd;
        text-decoration: underline;
        font-family: Anakotmai-Medium;
      }
    }

    &__setting {
      border-top: 1px solid #e8e8e8;
      padding-top: calc(var(--global-space) * 0.8);

      &__p {
        font-size: 16px;
      }

      &__Grid {
        display: grid;
        gap: 16px 48px;
        grid-template-columns: 1fr 166px;
        align-items: center;
      }

      &__input {
        outline: none;
        border: 1px solid #e8e8e8;
        padding: calc(var(--global-space) * 0.65)
          calc(var(--global-space) * 0.8);
        font-size: 16px;
        line-height: 21px;
        font-family: Anakotmai-Medium;
      }

      &__select {
        border: 1px solid #e8e8e8;
        padding: calc(var(--global-space) * 0.65)
          calc(var(--global-space) * 0.8);
        outline: none;
        font-family: Anakotmai-Medium;
      }

      &__datepicker {
        border: 1px solid #e8e8e8;
        padding: calc(var(--global-space) * 0.65)
          calc(var(--global-space) * 0.8);
        font-family: Anakotmai-Medium;
        width: 100%;
      }

      &__Button-wrapper {
        grid-column: 1/-1;
        display: grid;
        gap: calc(var(--global-space));
        grid-template-columns: 2fr 3fr;
      }
    }
  }

  &__right {
    padding-top: calc(var(--global-space) * 2.8);

    .Table {
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
      grid-template-columns: 1fr 200px max-content 60px;
      grid-template-rows: 52px;
      border-bottom-right-radius: 8px;
      max-height: 650px;
      overflow-y: auto;
    }

    .Table__col {
      padding-top: calc(var(--global-space) * 0.9);
      padding-bottom: calc(var(--global-space) * 1.1);
      height: 85px;
      align-items: start;

      &--header {
        align-items: center;
        height: 100%;
        padding: calc(var(--global-space) * 0.7);
      }
    }

    &__url-wrapper {
      display: grid;

      &__div {
        display: flex;
        align-items: center;
      }

      &__p {
        font-family: Anakotmai-Light;
        font-size: 12px;
      }
    }

    &__link {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 233px;
      border: none;
      outline: none;
      display: block;
      font-family: Anakotmai-Medium;
    }

    &__input {
      border: 1px solid #e8e8e8;
      outline: none;
      font-size: 16px;
      font-family: Anakotmai-Medium;
      text-overflow: ellipsis;
      display: inline-block;
      width: 100%;
      padding: calc(var(--global-space) * 0.2) calc(var(--global-space) * 0.4);
      line-height: 1;
    }

    &__no-password {
      font-family: Anakotmai-Light;
      color: #ccc;
    }

    &__button {
      border: none;
      background-color: transparent;
      outline: none;

      &--copy {
        width: 12px;
        height: 12px;
        background: no-repeat center/contain
          url(image-path('app/RoomSharing__Copy.svg'));
        display: inline-block;
        margin-left: calc(var(--global-space) * 0.4);
      }

      &--bin {
        width: 20px;
        height: 20px;
        background: no-repeat center/contain
          url(image-path('app/RoomSharing__Bin.svg'));
      }

      &--hide {
        width: 20px;
        height: 20px;
        background: no-repeat center/contain
          url(image-path('app/RoomSharing__Right__hide.svg'));
      }
    }
  }
}

.ViewsShow {
  display: grid;
  justify-content: center;
  font-family: Anakotmai-Medium;
  padding-top: calc(var(--global-space) * 2);

  &__wrapper {
    width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__form {
    &__svg {
      width: 62px;
      height: 103px;
      background: no-repeat center/contain
        url(image-path('app/ViewsShow__svg.svg'));
      margin-bottom: calc(var(--global-space) * 0.4);
    }

    &__h3 {
      font-size: 24px;
    }

    &__p {
      font-family: Anakotmai-Light;
      text-align: center;
      margin-bottom: calc(var(--global-space) * 1.2);
    }

    &__error {
      color: #f97272;
      margin-bottom: calc(var(--global-space) * 0.6);
      align-self: flex-start;
    }

    .Input {
      text-align: center;
      margin-bottom: calc(var(--global-space) * 0.6);
    }

    .Button {
      width: 100%;
    }
  }
}

.ImportIndex {
  font-family: Anakotmai-Medium;

  &__import {
    padding: calc(var(--global-space) * 1.6);
    background: #ffffff;
    border-bottom: 1px solid #e8e8e8;

    &__svg {
      width: 41px;
      height: 48px;
      background: no-repeat center/contain
        url(image-path('app/ImportIndex__import__svg'));
      grid-row: 1 / -1;
    }

    &__title {
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-template-rows: repeat(2, max-content);
      column-gap: 24px;
      margin-bottom: calc(var(--global-space) * 1.1);
    }

    &__p {
      &--light {
        font-family: Anakotmai-Light;
      }
    }

    &__dropzone {
      border-radius: 8px;
      border: 2px dashed #e8e8e8;
      padding: calc(var(--global-space) * 2.2) 0 calc(var(--global-space) * 1.6);
      display: flex;
      flex-direction: column;
      align-items: center;

      &__svg {
        width: 108px;
        height: 109px;
        background: no-repeat center/contain
          url(image-path('app/ImportIndex__import__dropzone__svg.svg'));
        margin-bottom: calc(var(--global-space) * 1.2);
      }

      &__p {
        font-size: 16px;

        &--light {
          font-family: Anakotmai-Light;
          margin-bottom: calc(var(--global-space) * 0.7);
        }
      }

      .Button {
        width: 248px;
      }
    }
  }

  &__export {
    padding: calc(var(--global-space) * 1.6);
    background: #ffffff;

    &__svg {
      width: 41px;
      height: 48px;
      background: no-repeat center/contain
        url(image-path('app/ImportIndex__export__svg'));
      grid-row: 1 / -1;
    }

    &__title {
      display: grid;
      grid-template-columns: max-content 1fr max-content max-content;
      grid-template-rows: repeat(2, max-content);
      column-gap: 24px;

      .Button {
        grid-row: 1 / -1;
      }
    }

    &__p {
      &--light {
        font-family: Anakotmai-Light;
        grid-column: 2 / 3;
      }
    }

    &__download-button {
      grid-column: -2 / -3;
    }

    &__export-button {
      grid-column: -1 / -2;
    }

    &__exporting {
      font-family: Anakotmai-Light;
      grid-column: -3 / -1;
      grid-row: 1 / -1;
      text-decoration: underline;
      display: flex;

      &__refresh {
        background: no-repeat center/contain
          url(image-path('app/ImportIndex__refresh__svg'));
        width: 18px;
        height: 24px;
        margin-right: 8px;
      }
    }
  }
}

.EncryptionIndex {
  font-family: Anakotmai-Medium;

  &__wrapper {
    background-color: #ffffff;
    padding: calc(var(--global-space) * 1.5);
  }

  &__switch-panel {
    display: grid;
    grid-template-columns: max-content 1fr 60px;
    grid-template-rows: repeat(2, max-content);
    column-gap: calc(var(--global-space) * 0.8);
    margin-bottom: calc(var(--global-space) * 1.5);

    &__svg {
      width: 48px;
      height: 48px;
      background: no-repeat center/contain
        url(image-path('app/EncryptionIndex__switch-panel__svg'));
      grid-row: 1 / -1;
    }

    &__p {
      &--light {
        font-family: Anakotmai-Light;
      }
    }

    .Switch {
      grid-column: 3 / 4;
      grid-row: 1/-1;
    }
  }

  &__dashed {
    border: 2px dashed #e8e8e8;
    border-radius: 8px;
    padding: calc(var(--global-space) * 1.6);
    display: grid;
    grid-template-columns: 36px 1fr 256px;
    gap: calc(var(--global-space) * 1.2);
    align-items: center;
    margin-bottom: calc(var(--global-space) * 1.2);

    &__svg {
      width: 36px;
      height: 32px;
      background: no-repeat center/contain
        url(image-path('app/EncryptionIndex__dashed__svg'));
    }

    .Button {
      font-size: 16px;
      font-family: Anakotmai-Bold;
    }

    &__p {
      &--red {
        color: #f97272;
      }

      &--primary {
        color: var(--c-primary);
      }
    }
  }

  &__instruction {
    &__p {
      font-size: 16px;
      margin-bottom: calc(var(--global-space) * 0.4);
    }

    &__ol {
      font-family: Anakotmai-Light;
      list-style-type: decimal;
      padding-left: calc(var(--global-space) * 0.9);
      display: grid;
      gap: calc(var(--global-space) * 0.2);

      &__li {
        &__span {
          color: var(--c-primary);
          text-decoration: underline;
        }
      }
    }
  }

  &__modal {
    font-family: Anakotmai-Medium;
    text-align: center;
    display: grid;
    justify-items: center;
    gap: calc(var(--global-space) * 0.5);

    &__svg {
      width: 96px;
      height: 96px;
      background: no-repeat center/contain
        url(image-path('app/EncryptionIndex__alert-modal__svg.svg'));
    }

    &__h2 {
      font-size: 24px;
    }

    &__p {
      font-family: Anakotmai-Light;

      &__span {
        color: var(--c-primary);
      }
    }
  }
}

.WarningModal {
  margin-top: 14px;
  &__h2 {
    font-size: 24px;
  }

  &__p {
    font-family: Anakotmai-Light;
    font-size: 16px;
    margin-top: 8px;
  }
}
.Accordion {
  font-family: Anakotmai-Medium;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background-color: #ffffff;

  &__head {
    padding: calc(var(--global-space) * 0.75) calc(var(--global-space) * 1.2)
      calc(var(--global-space) * 0.7);
    font-size: 16px;
    cursor: pointer;
    border-radius: 8px;
    border-bottom: 1px solid transparent;
    transition: border-radius 0.2s, border-bottom 0.2s;
    display: grid;
    grid-template-columns: 1fr 24px;

    &__arrow {
      width: 24px;
      height: 24px;
      background: no-repeat center/contain
        url(image-path('app/Accordion__arrow.svg'));
      transition: transform 0.2s;
    }

    &--open {
      border-bottom: 1px solid #e8e8e8;
      border-radius: 0;

      .Accordion__head__arrow {
        transform: rotate(-180deg);
      }
    }
  }

  &__body {
    border-radius: 0 0 8px 8px;

    // animation property
    max-height: 0;
    transition: max-height 0.25s;
    overflow: hidden;

    &__wrapper {
      padding: calc(var(--global-space) * 0.75) calc(var(--global-space) * 1.2);
    }
  }
}

.FaqIndex {
  font-family: Anakotmai-Medium;
  display: grid;
  gap: 24px;
  align-items: start;

  &__title {
    font-size: 24px;
  }

  &__p {
    &--error {
      color: #f97272;
    }
  }

  .Accordion {
    font-size: 16px;

    a {
      color: var(--c-primary);
    }

    ol {
      padding-left: calc(var(--global-space) * 0.9);
      list-style-type: decimal;
    }

    &__body {
      &__wrapper {
        > *:not(:last-child) {
          margin-bottom: calc(var(--global-space) * 0.5);
        }
      }
    }
  }
}

.Pagination {
  font-family: Anakotmai-Medium;
  font-size: 14px;
  color: #2b2b2b;
  padding: 0 calc(var(--global-space) * 1);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: start;
  grid-column: 1/8;

  &__nav {
    display: grid;
    grid-template-columns: repeat(6, max-content);
    gap: calc(var(--global-space) * 0.25);
    margin-left: auto;

    &__detail {
      margin-right: calc(var(--global-space) * 1);
    }

    &__a {
      display: flex;
      align-items: center;
      justify-content: start;
      font-size: 24px;
      margin-top: calc(var(--global-space) * -0.15);
      color: #00aefd;
    }

    &__a.disabled {
      color: #c4c4c4;
    }

    &__pages {
      display: grid;
      grid-template-columns: repeat(5, max-content);
      gap: calc(var(--global-space) * 0.25);
      align-items: center;
      justify-content: start;

      &__input {
        font-family: Anakotmai-Medium;
        font-size: 14px;
        color: #2b2b2b;
        border: none;
        border-bottom: 1px solid #e8e8e8;
        padding: 0;
        width: 30px;
        text-align: center;
        background-color: #ffffff;

        &:focus {
          outline: none;
          border: none;
          border-bottom: 1px solid #2b2b2b;
        }

        &::placeholder {
          color: #e8e8e8;
        }
      }
    }
  }
}

.Calendar {
  padding: calc(var(--app-padding) * 0.25);
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 100px max-content;
  background-color: white;
  width: max-content;
  box-shadow: 0 1px 5px #e8e8e8;
  position: absolute;
  z-index: 10;
  top: 72px;

  &__menu {
    display: 'flex';
    flex: 1;
    flex-direction: 'column';
    gap: '16px';
    .Button--ghost {
      font-size: 13px;
      padding: calc(var(--app-padding) * 0.5);
    }
  }

  .react-calendar {
    font-family: 'Anakotmai' !important;
    border: none !important;

    &__tile--now {
      &:not(.react-calendar__tile--active) {
        background: #99ddff !important;
        &:hover {
          background: #91d1f1 !important;
        }
      }
    }
  }

  &--last_row,
  &--last_col {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  &--last_col {
    justify-content: end;
  }
}
